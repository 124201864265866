<template>
  <iframe
    width="100%"
    height="400"
    :src="videoUrl"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "EmbedYoutubeVideo",

  props: ["source"],

  computed: {
    youtubeVideoId() {
      const url = this.source;
      const pattern = /([\w\d-]{11})/g;
      return url.match(pattern).pop();
    },

    videoUrl() {
      const id = this.youtubeVideoId;
      return `https://www.youtube.com/embed/${id}`;
    }
  }
};
</script>
