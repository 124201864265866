<template>
  <div v-if="user" class="has-fullwidth">
    <profile-card class="has-fullwidth" v-bind="{ picture, firstName, lastName, description }"></profile-card>
    <div v-if="suggestions && suggestions.length">
      <b-m-day-select class="mt-1" :days="days" :limit="3"></b-m-day-select>
      <template v-if="currentlySelectedDay">
        <div class="bm-create-meeting-title">
          {{ $t("business_matching.business_matching_time_and_place") }}
        </div>
        <div class="bm-create-meeting-subtitle">
          {{ $t("business_matching.business_matching_propose_time") }}
        </div>

        <div class="bm-create-meeting-title">
          {{ $t("business_matching.business_matching_suggested_time_slots_title") }}
        </div>
        <b-m-time-slot-select
          class="mt-1"
          ref="commonTimeSlots"
          v-if="commonTimeSlots.length"
          :time-slots="commonTimeSlots"
          @input="selectCommonTimeSlot"
        ></b-m-time-slot-select>

        <template v-if="mismatchedTimeSlots && mismatchedTimeSlots.length">
          <template v-if="mismatchedVisible">
            <div class="bm-create-meeting-title">
              {{ $t("business_matching.business_matching_other_time_slots_title") }}
            </div>
            <b-m-time-slot-select
              class="mt-1"
              ref="mismatchedTimeSlots"
              :time-slots="mismatchedTimeSlots"
              @input="selectMismatchedTimeSlot"
            ></b-m-time-slot-select>
          </template>

          <div class="has-text-centered" v-else>
            <b-button class="button is-primary" @click="showMismatchedTimeSlots">
              {{ $t("business_matching.business_matching_other_time_slots_uppercase") }}
            </b-button>
          </div>
        </template>

        <div class="has-text-danger mt-1" v-if="timeSlotError">
          {{ $t("business_matching.business_matching_meeting_validation_time_slot_error") }}
        </div>

        <template v-if="selectedTimeSlot">
          <div class="bm-create-meeting-title mt-2">{{
              $t("business_matching.business_matching_place_for_meeting")
            }}
          </div>
          <dropdown-select
            class="mt-2"
            :items="placesFromCurrentTimeSlot"
            :value="selectedPlaceIndex"
            position="is-top-left"
            @input="selectPlace"
          >
            <template #dropdown="{ item }">
              <span>{{ item.value }}</span>
            </template>

            <template #placeholder>
              <span class="has-text-secondary">{{
                  $t("business_matching.business_matching_place_for_meeting_empty")
                }}</span>
            </template>
          </dropdown-select>

          <div class="has-text-danger mt-1" v-if="placeError">
            {{ $t("business_matching.business_matching_select_place") }}
          </div>
        </template>

        <div class="bm-create-meeting-title mt-2">{{
            $t("business_matching.business_matching_additional_details")
          }}
        </div>
        <div class="control my-1">
          <textarea class="textarea" v-model="additionalInfo"></textarea>
        </div>

        <b-button class="button has-fullwidth is-primary mt-2" @click="send">
          {{ $t("business_matching.business_matching_send_invitation_uppercase") }}
        </b-button>
        <b-loading :active="isSending"></b-loading>
      </template>
    </div>
    <div v-else-if="isRequesting">
      <list-loading-indicator :active="true"></list-loading-indicator>
    </div>
    <div v-else class="mt-3 has-text-secondary has-text-centered">
      {{ $t("business_matching.business_matching_semi_empty_suggestions_placeholder") }}
    </div>
  </div>
  <div v-else>
    <list-loading-indicator :active="true"></list-loading-indicator>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import ProfileCard from "@/web/components/shared/ProfileCard";
import DropdownSelect from "@/shared/components/DropdownSelect";
import BMDaySelect from "@/web/components/businessmatching/BMDaySelect";
import { positionAndCompany } from "@/shared/utils";
import Constants from "@/web/constants";
import BMTimeSlotSelect from "@/web/components/businessmatching/BMTimeSlotSelect";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "BMCreateSemiMeeting",

  components: {ListLoadingIndicator, BMTimeSlotSelect, ProfileCard, DropdownSelect, BMDaySelect},

  mixins: [RequestMixin],

  props: {
    component: {
      type: Object,
      required: true,
    },

    userUuid: {
      type: String,
      required: true,
    },

    meeting: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      selectedTimeSlot: null,
      selectedPlaceIndex: null,
      additionalInfo: "",
      suggestions: null,
      mismatchedVisible: false,
      validated: false,
    };
  },

  computed: {
    ...mapGetters("attendees", ["getUserById"]),
    ...mapGetters("businessMatchingSuggestions", ["currentlySelectedDay", "isSending", "sendError"]),

    user() {
      return this.getUserById(this.userUuid);
    },

    firstName() {
      return this.user.first_name;
    },

    lastName() {
      return this.user.last_name;
    },

    picture() {
      return this.user.picture;
    },

    description() {
      return positionAndCompany(this.user);
    },

    days() {
      return (
        this.suggestions &&
        this.suggestions
          .map(it => it.day)
          .filter((value, index, self) => {
            return self.findIndex(day => day.only_date === value.only_date) === index;
          })
      );
    },

    suggestionsFromCurrentDay() {
      return this.currentlySelectedDay && this.suggestions && this.suggestions.find(it => it.day.id === this.currentlySelectedDay.id);
    },

    commonTimeSlots() {
      if (this.suggestionsFromCurrentDay) {
        if (this.suggestionsFromCurrentDay.common.length) {
          return this.suggestionsFromCurrentDay.common;
        } else {
          return this.suggestionsFromCurrentDay.mismatched;
        }
      } else {
        return [];
      }
    },

    mismatchedTimeSlots() {
      if (this.suggestionsFromCurrentDay && this.suggestionsFromCurrentDay.common.length) {
        return this.suggestionsFromCurrentDay.mismatched;
      }
      return [];
    },

    placesFromCurrentTimeSlot() {
      return this.selectedTimeSlot && this.selectedTimeSlot.places;
    },

    timeSlotError() {
      if (this.validated) {
        if (this.meeting) {
          return !this.selectedTimeSlot;
        }
      }
      return false;
    },

    placeError() {
      if (this.validated) {
        if (this.meeting) {
          return !(this.selectedPlaceIndex !== null && this.selectedPlaceIndex >= 0);
        } else {
          return !((this.selectedTimeSlot && typeof this.selectedPlaceIndex == "number") || !this.selectedTimeSlot);
        }
      }
      return false;
    },

    hasAnyError() {
      return this.timeSlotError || this.placeError;
    },
  },

  methods: {
    ...mapActions("businessMatchingSuggestions", ["fetchSemiRideSuggestions", "createInvitation"]),
    ...mapActions("businessMatchingMeetings", ["rescheduleMeeting"]),

    selectPlace(value) {
      this.selectedPlaceIndex = value;
    },

    showMismatchedTimeSlots() {
      this.mismatchedVisible = true;
    },

    send() {
      this.validated = true;
      if (!this.hasAnyError) {
        const data = {
          componentId: this.component.id,
          timeStart: this.selectedTimeSlot && this.selectedTimeSlot.time_start,
          timeEnd: this.selectedTimeSlot && this.selectedTimeSlot.time_end,
          userUuid: this.userUuid,
          message: this.additionalInfo,
          placeId: this.placesFromCurrentTimeSlot && this.placesFromCurrentTimeSlot[this.selectedPlaceIndex].id,
          sessionId: this.selectedTimeSlot && this.selectedTimeSlot.session_id,
        };
        if (this.meeting) {
          this.rescheduleMeeting({
            componentId: this.meeting.event_component_id,
            meetingId: this.meeting.id,
            data: data,
          }).then(result => {
            this.$emit("close");
          });
        } else {
          this.createInvitation(data).then(result => {
            this.$emit("close");
          });
        }
      }
    },

    selectCommonTimeSlot(timeslot) {
      this.selectedPlaceIndex = null;
      this.selectedTimeSlot = timeslot;
      if (this.$refs.mismatchedTimeSlots) {
        this.$refs.mismatchedTimeSlots.deselect();
      }
    },

    selectMismatchedTimeSlot(timeslot) {
      this.selectedPlaceIndex = null;
      this.selectedTimeSlot = timeslot;
      this.$refs.commonTimeSlots.deselect();
    }
  },

  requests: {
    async fetchSuggestions() {
      if (this.component.additional_info.business_matching_type === Constants.BUSINESS_MATCHING_TYPE_SEMI) {
        await this.fetchSemiRideSuggestions({
          componentId: this.component.id,
          userUuid: this.userUuid,
        }).then(suggestions => {
          this.suggestions = suggestions;
        });
      }
    },
  },

  mounted() {
    this.fetchSuggestions();
  },
};
</script>

<style scoped></style>
