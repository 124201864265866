export const Constants = Object.freeze({
  API_HOST_URL: "https://api.meetingapplication.com/api/v1",
  API_HEADER_DEVICE_ID: "APIcmsLP",
  API_HEADER_ACCEPT_JSON: "application/json",

  JITSI_URL: "https://meet.jit.si/",

  // Facebook
  FACEBOOK_API_KEY: process.env.VUE_APP_FACEBOOK_API_KEY,

  //Apple
  APPLE_CLIENT_ID: process.env.VUE_APP_APPLE_CLIENT_ID,
  APPLE_REDIRECT_URL: process.env.VUE_APP_APPLE_REDIRECT_URL,

  //Firebase
  ANALYTICS_PROPERTIES: {
    EVENT_ID: "event_id",
    DEVICE_ID: "device_id",
  },

  ANALYTICS_EVENTS: {
    ENTER_APP: "enter_app",
    USER_REGISTERED: "user_registered",
    USER_LOGGED_IN: "user_logged_in",
    OPEN_PUSH_NOTIFICATION: "open_push_notification",
    PAGE_VIEW: "page_view",
    DISPLAY_COMPONENT: "display_component",
    DISPLAY_SCREEN: "display_screen",
    PURCHASE_EVENT_TICKET_INIT: "purchase_event_ticket_init",
    PURCHASE_EVENT_TICKET_PAYMENT: "purchase_event_ticket_payment",
    PURCHASE_EVENT_TICKET_FINISH: "purchase_event_ticket_finish",
  },

  ANALYTICS_PARAMETERS: {
    PAGE_TITLE: "page_title",
    VIEW_TAG: "view_tag",
    SOURCE_VIEW_TAG: "source_view_tag",
    COMPONENT_ID: "component_id",
    VIEW_ID: "view_id",
    AUTHORIZATION_TYPE: "authorization_type",
    TICKET_ID: "ticket_id",
    PAYMENT_TYPE: "payment_type",
  },

  ANALYTICS_AUTHORIZATION_TYPES: {
    EMAIL: "email",
    FACEBOOK: "facebook",
    LINKED_IN: "linked_in",
    APPLE: "apple",
    GOOGLE: "google",
  },

  ANALYTICS_VIEW_TAGS: {
    PARTNER_COMPONENT: "partner_component",
    PARTNER_PROFILE: "partner_profile_view",
    PURCHASE_TICKET_STEP_FIRST: "purchase_ticket_step_first",
    PURCHASE_TICKET_STEP_SECOND: "purchase_ticket_step_second",
    PURCHASE_TICKET_STEP_PAYMENT: "purchase_ticket_step_payment",
    PURCHASE_TICKET_STEP_FINAL: "purchase_ticket_step_final",
  },

  //Component Names
  ADMIN_COMPONENT_TYPE: "AdminComponent",
  AGENDA_COMPONENT_TYPE: "AgendaComponent",
  EXHIBITORS_COMPONENT_TYPE: "ExhibitorComponent",
  PARTNERS_COMPONENT_TYPE: "PartnerComponent",
  ATTENDEES_COMPONENT_TYPE: "AttendeesComponent",
  SPEAKERS_COMPONENT_TYPE: "SpeakerComponent",
  QUIZZES_COMPONENT_TYPE: "SurveyQuizComponent",
  FEED_WALL_COMPONENT_TYPE: "FeedWallComponent",
  BUSINESS_MATCHING_COMPONENT_TYPE: "BusinessMatchingComponent",
  EVENT_INFO_COMPONENT: "EventInfoComponent",

  //Event access mode types
  EVENT_ACCESS_TYPE_NONE: "none",
  EVENT_ACCESS_TYPE_OPEN: "open",
  EVENT_ACCESS_TYPE_CLOSE: "close",
  EVENT_ACCESS_TYPE_WHITELIST: "whitelist",

  //LocalStorage keys
  EVENT_ACCESS_CODE_KEY: "event_access_code_",
  EVENT_ID_KEY: "event_id_key",

  //Rest error codes
  NOT_AUTHORIZED: "not_authorized",
  REST_NO_GDPR_ERROR: "sign_in.no_gdpr",
  REST_TOKEN_EXPIRED: "token_expired",
  REST_WHITE_LIST_ERROR: "not_authorized_with_whitelist",
  REST_WHITE_LIST_ALREDY_REQUESTED_ERROR: "join_user_to_event.still_waiting",
  REST_ACCESS_CODE_INVALID_ERROR: "user_access.access_code_invalid",
  REST_ACCESS_CODE_MISSING_ERROR: "user_access.access_code_missing",
  REST_EMAIL_TAKEN_ERROR: "user.email.taken",
  REST_EMAIL_NEEDS_TO_BE_CONFIRMED: "user.email_need_to_be_confirmed",
  REST_SIGN_IN_EMAIL_NEEDS_TO_BE_CONFIRMED: "sign_in.email_not_confirmed",
  REST_EMAIL_ACCOUNT_ALREADY_CONFIRMED: "user.token.resend_not_needed_account_confirmed",
  REST_EMAIL_ACCOUNT_NOT_FOUND: "user.token.resend_not_found_user",
  REST_RESET_PASSWORD_TOKEN_EXPIRED: "user.change_password.incorrect_token",
  FEED_WALL_POST_ALREADY_LIKED_ERROR_CODE: "feed_wall.rating.allready_liked",
  FEED_WALL_POST_NOT_LIKED_YET_ERROR_CODE: "feed_wall.rating.not_liked_yet",
  AGENDA_QUESTION_ALREADY_LIKED_ERROR_CODE: "agenda.discussion.ratings.already_liked",
  AGENDA_QUESTION_NOT_LIKED_YET_ERROR_CODE: "agenda.discussion.ratings.not_found_like",
  AGENDA_QUESTION_WAITING_FOR_MODERATION_ERROR_CODE: "agenda_session_discussion.waiting_for_moderation",
  PAYMENT_DISCOUNT_CODE_INVALID_ERROR_CODE: "discount_code.code_invalid",
  PAYMENT_DISCOUNT_CODE_USED_ERROR_CODE: "discount_code.code_used",
  PAYMENT_INIT_CODE_NOT_FOUND_ERROR_CODE: "discount_code.code_not_found",
  PAYMENT_INIT_CODE_USED_ERROR_CODE: "discount_code.code_used",
  PAYMENT_INIT_TICKET_NOT_FOUND_ERROR_CODE: "payment.ticket.not_found",
  PAYMENT_INIT_TICKET_SOLD_OUT_ERROR_CODE: "payment.ticket.ticket_soldout",
  PAYMENT_INIT_EMAIL_NOT_ON_WHITELIST_ERROR_CODE: "payment.ticket.user_not_whitelisted",
  PAYMENT_INIT_EMAIL_ALREADY_HAS_AN_TICKET_ERROR_CODE: "payment.ticket.user_already_have_ticket",

  //Module types
  MODULE_EVENT_TICKETS: "component_event_tickets",
  MODULE_MOBILE_APP_INFO: "mobile_app_info",
  MODULE_SOCIAL_LINKS: "component_social_links",
  MODULE_IFRAME: "iframe",
  MODULE_IFRAME_NATIVE: "iframe_native",
  MODULE_COMPONENT_AGENDA: "component_agenda",
  MODULE_COMPONENT_SPEAKERS: "component_speakers",
  MODULE_COMPONENT_ATTENDEES: "component_attendees",
  MODULE_COMPONENT_EXHIBITORS: "component_exhibitors",
  MODULE_COMPONENT_PARTNERS: "component_partners",
  MODULE_COMPONENT_LIVE_WEBINAR: "component_live_webinar",
  MODULE_EVENT_DESCRIPTION: "component_event_description",
  MODULE_ATTENDEES: "component_attendees",
  MODULE_FEED_WALL: "component_feed_wall",
  MODULE_QUIZZES: "component_quizzes",
  MODULE_TWITTER: "component_twitter",
  MODULE_CUSTOMER_SUPPORT: "component_customer_support",
  MODULE_BUTTON_POPUP: "component_button_popup",
  MODULE_EVENT_TIMER: "component_event_timer",
  MODULE_FAQ: "component_faq",
  MODULE_BUSINESS_MATCHING: "component_business_matching",
  MODULE_EVENT_CONTACT_INFO: "component_event_contact_information",

  //Navbar modules
  MODULE_NAVBAR_URL_REDIRECT: "redirect",
  MODULE_NAVBAR_MODAL: "modal",

  //Module fields
  MODULE_FIELD: {
    ONLY_FOR_LOGGED_IN: "only_for_logged_in",
    ONLY_FOR_GUESTS: "only_for_guests",
    VISIBLE: "visible",
    COMPONENT_ID: "component_id",
  },

  AGENDA_MODULE_FIELDS: {
    LP_MODE: "lp_mode",
    HIDE_SEARCH: "hide_search",
    HIDE_DAYS: "hide_days",
    HIDE_SESSION_TIME: "hide_session_time",
    SHOW_LIVE_INDICATOR: "show_live_indicator",
    CUSTOM_SPONSORS: "custom_sponsors",
    COMPONENT_IDS_WITH_RATING_ALWAYS_ENABLED: "component_ids_with_rating_always_enabled",
  },

  //Module navbar actions
  MODULE_NAVBAR_BEHAVIOUR_SCROLL: "scroll",
  MODULE_NAVBAR_BEHAVIOUR_PAGE: "page",

  //Friends invitation types
  FRIENDS_INVITATION_REQUESTED: "invitation_requested",
  FRIENDS_INVITATION_PENDING: "invitation_pending",

  //Friends statuses
  FRIEND_ACCEPTED: "accepted",
  FRIEND_PENDING: "pending",
  FRIEND_REQUESTED: "requested",
  FRIEND_NOT_FRIEND: "not_friend",

  //Notification types
  NOTIFICATION_TYPE: {
    EVENT: "event",
    BUSINESS_MATCHING: "business_matching",
    FEED_WALL: "feed_wall",
    FRIENDS: "friends",
    INBOX: "inbox",
    USER: "user",
  },

  //Notification subtypes
  NOTIFICATION_SUBTYPE: {
    EVENT_STANDARD: "event_standard",
    EVENT_NEWS: "event_news",
    EVENT_SURVEY: "event_survey",
    EVENT_SOCIAL_MEDIA: "event_social_media",
    BUSINESS_MATCHING_INVITATION_CREATED: "business_matching_create_invitation",
    BUSINESS_MATCHING_INVITATION_UPDATED: "business_matching_update_invitation",
    BUSINESS_MATCHING_INVITATION_DELETED: "business_matching_delete_invitation",
    BUSINESS_MATCHING_REMINDER: "business_matching_reminder",
    BUSINESS_MATCHING_JOINED_CALL: "business_matching_joined_call",
    FEED_WALL_NEW_COMMENT: "feed_wall_new_comment",
    FEED_WALL_LIKE: "feed_wall_like",
    FRIENDS_NEW_INVITATION: "friends_new_invitation",
    FRIENDS_INVITATION_ACCEPTED: "friends_invitation_accepted",
    INBOX: "inbox_new_message",
    AGENDA_REMINDER: "agenda_reminder",
    USER_JOIN_VIDEO_CALL: "user_join_video_call",
  },

  //Quiz modes
  QUIZ_MODE: {
    QUIZ: "quiz",
    SURVEY: "survey",
  },

  QUIZ_ANSWER_MODE: {
    RADIO: 0,
    CHECKBOX: 1,
    TEXT: 2,
  },

  //Route names
  ROUTE_DASHBOARD: "route_dashboard",
  ROUTE_INVALID: "route_invalid",
  ROUTE_MAIN_INTRODUCTION: "route_main_introduction",
  ROUTE_LEGAL: "route_legal",
  ROUTE_MY_PROFILE: "route_my_profile",
  ROUTE_EDIT_PROFILE: "route_edit_profile",
  ROUTE_EDIT_EMAIL: "route_edit_email",
  ROUTE_EDIT_PASSWORD: "route_edit_password",
  ROUTE_AVAILABILITY: "route_availability",
  ROUTE_FRIENDS: "route_friends",
  ROUTE_FRIENDS_INVITATIONS: "route_friends_invitations",
  ROUTE_INBOX: "route_inbox",
  ROUTE_INBOX_CREATE_THREAD: "route_inbox_create_thread",
  ROUTE_INBOX_THREAD: "route_inbox_thread",
  ROUTE_EXHIBITOR: "route_exhibitor",
  ROUTE_ATTENDEES: "route_attendees",
  ROUTE_BUSINESS_MATCHING: "route_business_matching",
  ROUTE_BUSINESS_MATCHING_ACCEPTED: "route_business_matching_accepted",
  ROUTE_BUSINESS_MATCHING_PENDING: "route_business_matching_pending",
  ROUTE_QUIZZES: "route_quizzes",
  ROUTE_QUIZ_QUESTIONS: "route_quiz_questions",
  ROUTE_AGENDA_SESSION_ATTENDEES: "route_agenda_session_attendees",
  ROUTE_BUY_TICKET: "route_buy_ticket",
  ROUTE_BUY_TICKET_NO_SELECTION: "route_buy_ticket_no_selection",
  ROUTE_BUY_TICKET_CONFIRMATION: "route_buy_ticket_confirmation",
  ROUTE_ACCESS_CODE: "route_access_code",
  ROUTE_LOGIN: "route_login",
  ROUTE_REGISTER: "route_register",
  ROUTE_FORGOT_PASSWORD: "route_forgot_password",
  ROUTE_FORGOT_PASSWORD_EVENTLESS: "route_forgot_password_eventless",
  ROUTE_RESET_PASSWORD_EVENTLESS: "route_reset_password_eventless",
  ROUTE_RESET_PASSWORD: "route_reset_password",
  ROUTE_SESSION_DISCUSSION: "route_session_discussion",
  ROUTE_QUIZ_LEADERBOARD: "route_quiz_leaderboard",
  ROUTE_SURVEY_RESULTS: "route_survey_results",
  ROUTE_TREASURE_HUNT_LEADERBOARD: "route_treasure_hunt_leaderboard",
  ROUTE_EMAIL_CONFIRMATION_SUCCESS: "route_email_confirmation_success",
  ROUTE_EMAIL_CONFIRMATION_TOKEN_EXPIRED: "route_email_confirmation_token_expired",
  ROUTE_EMAIL_CONFIRMATION_TOKEN_INVALID: "route_email_confirmation_token_invalid",
  ROUTE_MODERATOR_MAIN: "route_moderator_main",
  ROUTE_MODERATOR_EXHIBITOR: "route_moderator_exhibitor",
  ROUTE_MODERATOR_EXHIBITOR_UPDATE: "route_moderator_exhibitor_update",
  ROUTE_MODERATOR_EXHIBITOR_CREATE_UPDATE: "route_moderator_exhibitor_create_update",
  ROUTE_MODERATOR_SPEAKER: "route_moderator_speaker",

  //PUSHER
  PUSHER_INBOX_UPDATED: "inbox-updated",
  PUSHER_INBOX_THREAD_UPDATED_ACTION: "updated-thread",
  PUSHER_USER_FRIENDS_UPDATED: "friends-updated",
  PUSHER_USER_FRIEND_INVITATIONS_UPDATED: "invitations-updated",
  PUSHER_USER_NOTIFICATIONS_UPDATED: "notifications-updated",
  PUSHER_EVENT_COMPONENT_UPDATED: "component-updated",
  PUSHER_EVENT_FEED_WALL_UPDATED: "feedwall-updated",
  PUSHER_EVENT_COMPONENT_LIST_UPDATED: "components-list-updated",
  PUSHER_USER_BUSINESS_MATCHING: "business-matching",


  PUSHER_FEEDWALL: "feedwall",
  PUSHER_FEEDWALL_CHANNEL: "channel",
  PUSHER_FEEDWALL_THREAD: "thread",
  PUSHER_FEEDWALL_COMMENT: "comment",
  PUSHER_QUIZ: "survey_q",
  PUSHER_AGENDA_SESSION: "agenda-session",
  PUSHER_DESTROY_ACTION: "destroy",

  //PUSHER ACTIONS
  PUSHER_ACTION_QUIZ_CREATED: "create-survey_quizzes",
  PUSHER_ACTION_QUIZ_DELETED: "destroy-survey_quizzes",
  PUSHER_ACTION_SESSION_QUESTION_CREATED: "create-agenda-session-discussion",
  PUSHER_ACTION_SESSION_QUESTION_CREATED_ADMIN: "create-admin-agenda-session-discussion",
  PUSHER_ACTION_SESSION_QUESTION_DELETED: "destroy-agenda-session-discussion",

  //BUSINESS MATCHING
  BUSINESS_MATCHING_INVITATION_STATUS_ACCEPTED: "accepted",
  BUSINESS_MATCHING_INVITATION_STATUS_RESCHEDULED: "rescheduled",
  BUSINESS_MATCHING_INVITATION_STATUS_DECLINED: "declined",
  BUSINESS_MATCHING_INVITATION_STATUS_PENDING: "pending",

  BUSINESS_MATCHING_TYPE_FREE: "free",
  BUSINESS_MATCHING_TYPE_SEMI: "semi",
  BUSINESS_MATCHING_TYPE_STRICT: "strict",

  //Social icon types
  SOCIAL_ICON_FACEBOOK: "social_icon_facebook",
  SOCIAL_ICON_LINKED_IN: "social_icon_linked_in",
  SOCIAL_ICON_INSTAGRAM: "social_icon_instagram",
  SOCIAL_ICON_YOUTUBE: "social_icon_youtube",
  SOCIAL_ICON_X: "social_icon_x",

  //FETCHING STATES
  STATUS_NO_DATA: "status_no_data",
  STATUS_LOADING: "status_loading",
  STATUS_LOADED: "status_loaded",
  STATUS_ERROR: "status_error",

  AUTH_LOADING_STATUSES: {
    LOGGING_IN: "logging_in",
    SIGNING_UP: "signing_up",
    JOINING_EVENT: "joining_event",
    SUBMITTING_REQUIRED_DATA: "submitting_required_data",
    DOWNLOADING_USER_DATA: "downloading_user_data",
    DOWNLOADING_EVENT_DATA: "downloading_event_data",
    SUCCESS: "auth_loading_success",
    ERROR: "error",
  },

  FEED_WALL_CHANNEL_NOT_FOUND: "FeedWallChannelNotFound",
  FEED_WALL_THREAD_NOT_FOUND: "FeedWallThreadNotFound",

  DEFAULT_BANNER_IMAGE_URL: "https://event.meetingapplication.com/widget/background_6.jpg",

  SIDE_MENU_BUBBLE_TYPES: {
    INBOX_MESSAGES: "inbox_messages_bubble",
    QUIZZES_BUBBLE: "quizzes_bubble",
    FEED_WALL_BUBBLE: "feed_wall_bubble",
    AGENDA_SESSION_QUESTIONS: "agenda_session_questions_bubble",
  },

  PURCHASE_SUMMARY_ROW_FIELDS: {
    TICKET: "ticket",
    DISCOUNT: "discount",
    DISCOUNTED_TICKETS_QUANTITY: "discounted_tickets_quantity",
    QUANTITY: "quantity",
    MARKETING_DISCOUNT: "marketing_discount",
    ADDON_ROW: "addon_row",
    ADDON_MODEL: "addon_model",
    ADDON_QUANTITY: "addon_quantity",
    BULK_DISCOUNT: "bulk_discount",
  },

  MODERATION_STATUS_ACCEPTED: "accepted",
  MODERATION_STATUS_CANCELED: "canceled",
  MODERATION_STATUS_PENDING: "pending",
  MODERATION_STATUS_REJECTED: "rejected",

  // DEDICATED CHANGES
  // Forum branży kosmetycznej id:710
  // https://event.meetingapplication.com/forum-branzy-kosmetycznej10241
  EVENT_FBK_ID: Number(process.env.VUE_APP_EVENT_FBK_ID),
});

export default Constants;
