export function createScriptLoader(src, callback) {
  let promise = null;

  return function(...args) {
    if (promise === null) {
      promise = createScriptPromise(src, callback, args);
    }

    return promise;
  };
}

async function createScriptPromise(src, callback, args) {
  await loadScript(src);

  if (typeof callback === "function") {
    return callback.apply(this, args);
  }
}

export function loadScript(src) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Failed to load script"));
  });
}
