<template>
  <div class="modal-container">
    <b-carousel
      v-model="currentIndex"
      animated="slide"
      :has-drag="true"
      :autoplay="false"
      :arrow="false"
      :pause-hover="false"
      :pause-info="false"
      :indicator-inside="false"
      pause-info-type="is-primary"
      :repeat="true"
      indicator-custom
      indicator-custom-size="95px"
    >
      <b-carousel-item
        class="modal-card"
        v-for="(exhibitor, index) in exhibitors"
        :key="index"
        v-click-outside="event => onClickOutside(event, index)"
      >
        <header class="modal-card-head">
          <exhibitor-banner class="has-fullwidth" :rounded-top-borders="true" :exhibitor="exhibitor"></exhibitor-banner>
        </header>

        <section class="modal-card-body">
          <exhibitor-body
            :exhibitor="exhibitor"
            :is-authorized="isAuthorized"
            :lp-mode="lpMode"
            :inbox-enabled="inboxEnabled"
            @close-modal="$parent.close()"
          ></exhibitor-body>
        </section>

        <footer class="modal-card-foot" :style="{ padding: '5px' }"></footer>
      </b-carousel-item>

      <template #indicators="props">
        <div
          v-if="getImageUrl(props.i)"
          :class="{ 'is-selected': props.i === currentIndex }"
          class="exhibitor-details-indicator box is-borderless is-inline-flex has-logo is-clipped"
        >
          <figure class="indicator-image image is-centered">
            <img :src="getImageUrl(props.i)" />
          </figure>
        </div>
        <div
          v-else
          :class="{ 'is-selected': props.i === currentIndex }"
          class="exhibitor-details-indicator box is-radius-small is-clipped has-logo"
        >
          <figure class="indicator-placeholder">
            <div class="indicator-placeholder-text">
              {{ getTitle(props.i) }}
            </div>
          </figure>
        </div>
      </template>
    </b-carousel>
  </div>
</template>

<script>
import ExhibitorBanner from "@/shared/components/exhibitors/ExhibitorBanner";
import ExhibitorBody from "@/shared/components/exhibitors/ExhibitorBody";

export default {
  name: "ExhibitorDetailsModal",

  components: {
    ExhibitorBody,
    ExhibitorBanner,
  },

  props: ["exhibitors", "exhibitorId", "events", "lpMode", "isAuthorized", "inboxEnabled"],

  data() {
    return {
      currentIndex: 0,
    };
  },

  mounted() {
    if (this.exhibitorId) {
      this.currentIndex = this.exhibitors.findIndex(exhibitor => exhibitor.id === this.exhibitorId);
    }
  },

  methods: {
    getImageUrl(index) {
      let picture = this.exhibitors[index].picture;
      return picture && picture.file_thumbnail_200_url;
    },

    getTitle(index) {
      return this.exhibitors[index].name;
    },

    onClickOutside(event, index) {
      if (index === this.currentIndex) {
        let carouselItems = document.getElementsByClassName("carousel-items");
        if (carouselItems && carouselItems.length) {
          if (carouselItems[0].contains(event.target)) {
            this.$parent.close();
          }
        }
      }
    },
  },
};
</script>

<style scoped>

.modal-container {
  width: 960px;
  @media (max-width: 968px) {
    width: 100%;
  }
}
.modal-card {
  height: 80vh;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.modal-card-head {
  padding: 0;
}
.modal-card-body {
  padding-top: 24px;
  padding-bottom: 30px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
