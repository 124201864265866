export function pluckError(err) {
  if (err && err.response) {
    return pluckErrorMessage(err.response);
  } else if (err && err.message) {
    return err.message;
  } else if (err && err.toString) {
    return err.toString();
  }

  return "";
}

export function pluckErrorCode(err) {
  if (typeof err === "string") {
    return null;
  }
  const { data } = err.response;
  if (data && data.errors && Array.isArray(data.errors)) {
    const error = data.errors[0];
    if (error && error.code) {
      return error.code;
    }
  }

  return null;
}


function pluckErrorMessage(response) {
  const { data } = response;
  if (data && data.errors && Array.isArray(data.errors)) {
    const error = data.errors.pop();
    if (error && error.message) {
      return error.message;
    }
  }

  return null;
}

export default { pluckError, pluckErrorCode };
