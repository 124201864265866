import tinycolor from "tinycolor2";

// Loader colors scheme
export const backgroundScheme = {
  ["--background-color"](def) {
    return def("background_color", "#ffffff");
  },
  ["--navbar-background-color"](def) {
    return def("navbar_background_color", "#ffffff");
  }
};

// Primary colors scheme
export const primaryScheme = {
  ["--primary-color"](def) {
    return def("main_color", "#007aff");
  },
  ["--primary-color-hover"](def) {
    const primary = def("main_color", "#007aff");
    const primaryHover = tinycolor(primary).darken(2.5);
    return def("main_color_hover", primaryHover.toHexString());
  },
  ["--primary-color-focus"](def) {
    const primary = def("main_color", "#007aff");
    const primaryFocus = tinycolor(primary);
    primaryFocus.setAlpha(0.25);
    return def("main_color_focus", primaryFocus.toRgbString());
  },
  ["--primary-color-active"](def) {
    const primary = def("main_color", "#007aff");
    const primaryActive = tinycolor(primary).darken(2.5);
    return def("main_color_active", primaryActive.toHexString());
  },
  ["--primary-color-text"](def) {
    const primary = def("main_color", "#007aff");
    const luminance = tinycolor(primary).getLuminance();
    return def("main_color_text", luminance > 0.55 ? "#333" : "#fff");
  },
};

export const textScheme = {
  ["--text-color-primary"](def) {
    return def("primary_text_color", "#000");
  },
  ["--text-color-secondary"](def) {
    return def("secondary_text_color", "#000");
  },
};

// Primary colors scheme
export const primaryInvertedScheme = {
  ["--primary-inverted-color"](def) {
    return def("main_color_text", "#ffffff");
  },
  ["--primary-inverted-color-hover"](def) {
    const primary = def("main_color_text", "#ffffff");
    const primaryHover = tinycolor(primary).darken(2.5);
    return def("main_color_hover", primaryHover.toHexString());
  },
  ["--primary-inverted-color-focus"](def) {
    const primary = def("main_color_text", "#ffffff");
    const primaryFocus = tinycolor(primary);
    primaryFocus.setAlpha(0.25);
    return def("main_color", primaryFocus.toRgbString());
  },
  ["--primary-inverted-color-active"](def) {
    const primary = def("main_color_text", "#ffffff");
    const primaryActive = tinycolor(primary).darken(2.5);
    return def("main_color_active", primaryActive.toHexString());
  },
  ["--primary-inverted-color-text"](def) {
    const primary = def("main_color", "#ffffff");
    const luminance = tinycolor(primary).getLuminance();
    return def("main_color", luminance > 0.55 ? "#333" : "#fff");
  },
};

// Link colors scheme
export const linkScheme = {
  ["--link-color"](def) {
    return def("accent_color", "#007aff");
  },
  ["--link-color-hover"](def) {
    const link = def("accent_color", "#007aff");
    const linkHover = tinycolor(link).darken(2.5);
    return def("accent_color_hover", linkHover.toHexString());
  },
  ["--link-color-focus"](def) {
    const link = def("accent_color", "#007aff");
    const linkFocus = tinycolor(link);
    linkFocus.setAlpha(0.25);
    return def("accent_color_focus", linkFocus.toRgbString());
  },
  ["--link-color-active"](def) {
    const link = def("accent_color", "#007aff");
    const linkActive = tinycolor(link).darken(2.5);
    return def("accent_color_active", linkActive.toHexString());
  },
  ["--link-color-text"](def) {
    const link = def("accent_color", "#007aff");
    const luminance = tinycolor(link).getLuminance();
    return def("accent_color_text", luminance > 0.55 ? "#333" : "#fff");
  },
};

// Loader colors scheme
export const loaderScheme = {
  ["--loader-color"](def) {
    return def("loader_color", "main_color", "#007aff");
  },
};

export default {
  ...backgroundScheme,
  ...primaryScheme,
  ...primaryInvertedScheme,
  ...linkScheme,
  ...loaderScheme,
  ...textScheme,
};
