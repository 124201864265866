<template>
  <div>
    <!-- SELECT USERS VIEW-->
    <div class="search-bar-container" :class="smallClass">
      <search-input
        v-model="searchQuery"
        :placeholder="$t('hints.search_hint_attendees')"
        border-radius="8px"
        :button-visible="false"
        :is-small="isSmall"
        class="has-fullwidth"
      ></search-input>
    </div>
    <div ref="componentAttendeesListElement" class="contact-list" :class="smallClass">
      <template v-if="attendees && attendees.length">
        <div @click="selectUser(user)" v-for="user in attendees" :key="user.user_identity_token">
          <user-list-item :user="user" :is-small="isSmall"></user-list-item>
        </div>
        <list-loading-indicator :active="isLoading"></list-loading-indicator>
      </template>
      <div v-else-if="showEmptyPlaceholder" class="has-text-centered has-text-secondary mt-3">
        {{ $t("inbox.empty_friends_search_placeholder") }}
      </div>
      <div v-else>
        <list-loading-indicator :active="isLoading"></list-loading-indicator>
      </div>
    </div>
  </div>
</template>

<script>
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import UserListItem from "@/web/components/inbox/InboxUserListItem";
import SearchInput from "@/shared/components/SearchInput";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {debounce, throttle} from "@/shared/utils";

export default {
  name: "ComponentAttendees",

  components: {
    ListLoadingIndicator,
    UserListItem,
    SearchInput,
  },

  props: {
    component: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedUser: null,
      searchQuery: "",
      scrollListener: null,
      firstMessageLoading: false,
    };
  },

  computed: {
    ...mapGetters("attendees", ["isLoading", "getAttendees"]),
    ...mapGetters("currentUser", {currentUserUuid: "uuid"}),

    scrollThreshold: () => 200,

    attendees() {
      return this.getAttendees(this.searchQuery, true);
    },

    showEmptyPlaceholder() {
      return !this.isLoading && this.inboxUsers && !this.inboxUsers.length;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapMutations("attendees", ["resetMeta", "setComponentAttendeesSource", "clearSearchResult"]),
    ...mapActions("attendees", ["loadComponentAttendeesPage", "searchAttendees"]),

    selectUser(user) {
      this.$emit("user-selected", user.user_identity_token);
    },

    addScrollListener() {
      if (this.$refs.componentAttendeesListElement && this.scrollListener === null) {
        this.scrollListener = ev => {
          const attendeesList = ev.target;
          let isOnBottomOfList = attendeesList.scrollTop > attendeesList.scrollHeight - attendeesList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.componentAttendeesListElement.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs.componentAttendeesListElement.removeEventListener("scroll", this.scrollListener);
        this.scrollListener = null;
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.loadComponentAttendeesPage();
    }, 1000),
  },

  watch: {
    searchQuery: debounce(function (newVal) {
      if (newVal.length) {
        this.searchAttendees(newVal);
      } else {
        this.clearSearchResult();
      }
    }, 500),
  },

  mounted() {
    this.resetMeta();
    this.setComponentAttendeesSource(this.component.id);
    this.loadComponentAttendeesPage();
    this.addScrollListener();
  },

  beforeDestroy() {
    this.removeScrollListener();
  },
};
</script>

<style scoped lang="scss">
.search-bar-container {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 32px 8px 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-small {
    height: 60px;
  }
}

.contact-list {
  overflow-y: auto;
  height: calc(100% - 100px);

  &.is-small {
    height: calc(100% - 60px);
  }
}
</style>
