<template>
  <div class="mb-2">
    <countries-autocomplete v-model="value" :multi-select="multiSelect" :label="label"></countries-autocomplete>
    <template v-if="errorsVisible">
      <p v-if="requiredError" class="help is-danger">{{ $t("common.field_required_error") }}</p>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";
import CountriesAutocomplete from "@/web/components/profile/CountriesAutocomplete";

export default {
  name: "RegisterCountryInput",

  components: {
    CountriesAutocomplete,
  },

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: "",
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    hasRequiredValidation() {
      return FormBuilderConstants.VALIDATIONS.REQUIRED in this.validation;
    },

    requiredError() {
      return this.hasRequiredValidation && !this.validation.required;
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },

    multiSelect() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.MULTI_SELECT];
    }
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue);
      this.$emit("form-input");
    },
  },
};
</script>

<style scoped></style>
