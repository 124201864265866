import dayjs from '@/shared/utils/day';

const DATE_FORMAT_DEFAULT = "D MMM YYYY";
const DATE_FORMAT_DEFAULT_NUMERIC = "D/MM/YYYY";
const DATE_FORMAT_ONLY_DAYS = "D";
const DATE_FORMAT_DAYS_WITH_MONTH = "D MMMM";
const DATE_FORMAT_DAYS_WITH_SHORTHAND_MONTH = "D MMM";
const DATE_FORMAT_DATE_READABLE = "dddd, D MMMM";
const DATE_FORMAT_HOURS_WITH_MINUTES_24H = "HH:mm";
const DATE_FORMAT_HOURS_WITH_MINUTES_12H = "hh:mm A";
const DATE_FORMAT_TIME_WITH_DATE_12H = "hh:mm A D MMMM";
const DATE_FORMAT_TIME_WITH_DATE_24H = "HH:mm D MMMM";

/*
 * Detects browser's locale 24h time preference
 * It works by checking whether hour output contains a space ('1 AM' or '01')
 */
const isBrowserLocale24h = () => {
  return !new Intl.DateTimeFormat([], { hour: 'numeric' }).format(0).match(/\s/);
};

export function composeDayDate(timestamp, eventTimezone) {
  const date = dayjs(timestamp).tz(eventTimezone);
  return date.format(DATE_FORMAT_DATE_READABLE);
}

export function composeAgendaSessionTime(timestamp, eventTimezone) {
  const date = dayjs(timestamp).tz(eventTimezone);
  if (isBrowserLocale24h()) {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_24H);
  } else {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_12H);
  }
}

export function composeAgendaSessionRatingTime(timestamp, eventTimezone) {
  const date = dayjs(timestamp).tz(eventTimezone);
  if (isBrowserLocale24h()) {
    return date.format(DATE_FORMAT_TIME_WITH_DATE_24H);
  } else {
    return date.format(DATE_FORMAT_TIME_WITH_DATE_12H);
  }
}

export function composeInboxMessageDate(timestamp) {
  const userTimezone = dayjs.tz.guess();
  const date = dayjs(timestamp);
  return date.tz(userTimezone).format(DATE_FORMAT_DAYS_WITH_SHORTHAND_MONTH);
}

export function composeInboxMessageTime(timestamp) {
  const userTimezone = dayjs.tz.guess();
  const date = dayjs(timestamp).tz(userTimezone);
  if(isBrowserLocale24h()) {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_24H);
  } else {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_12H);
  }
}

export function composeSessionRange(startTimestamp, endTimestamp, eventTimezone) {
  const startMoment = dayjs(startTimestamp).tz(eventTimezone);
  return `${startMoment.format(DATE_FORMAT_DAYS_WITH_MONTH)}, ${getFormattedTimeWithTimezone(
    startTimestamp,
    eventTimezone
  )} - ${getFormattedTimeWithTimezone(endTimestamp, eventTimezone)}`;
}

export function getFormattedTimeWithTimezone(timestamp, eventTimezone) {
  const date = dayjs(timestamp).tz(eventTimezone);
  if(isBrowserLocale24h()) {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_24H);
  } else {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_12H);
  }
}

export function composeBMTimeSlotTime(dateString, timezone) {
  const date = dayjs(dateString).tz(timezone);
  if(isBrowserLocale24h()) {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_24H);
  } else {
    return date.format(DATE_FORMAT_HOURS_WITH_MINUTES_12H);
  }
}

export function composeBMTimeSlotDuration(startDateString, endDateString, i18n) {
  const startMoment = dayjs(startDateString);
  const endMoment = dayjs(endDateString);

  const minutesDiff = Math.abs(startMoment.diff(endMoment, "minute"));

  const hours = Math.floor(minutesDiff / 60);
  const minutes = minutesDiff % 60;
  if (hours > 0) {
    if (minutes > 0) {
      return i18n.t("time.time_duration_hours_and_minutes", [hours, minutes]);
    } else {
      return i18n.t("time.time_duration_hours", [hours]);
    }
  } else {
    return i18n.t("time.time_duration_minutes", [minutes]);
  }
}

export function composeElapsedTime(timestamp, i18n) {
  const currentDate = dayjs();
  const date = dayjs(timestamp);
  const durationInMinutes = currentDate.diff(date, 'minute');

  if (durationInMinutes <= 1) {
    return i18n.t("time.time_just_now_uppercase");
  } else if (durationInMinutes < 60) {
    return i18n.t("time.time_duration_minutes", [Math.floor(durationInMinutes)]);
  } else if (durationInMinutes < 24 * 60) {
    return i18n.t("time.time_duration_hours", [Math.floor(durationInMinutes / 60)]);
  } else if (date.year() === currentDate.year()) {
    return date.format(DATE_FORMAT_DAYS_WITH_SHORTHAND_MONTH);
  } else {
    return date.format(DATE_FORMAT_DEFAULT);
  }
}

export function composeElapsedTimeForModeration(timestamp, i18n) {
  const currentDate = dayjs();
  const date = dayjs(timestamp);
  const durationInMinutes = currentDate.diff(date, 'minute'); // Różnica czasu w minutach

  if (durationInMinutes <= 1) {
    return i18n.t("time.time_just_now_uppercase").toLowerCase();
  } else if (durationInMinutes < 60) {
    return i18n.t("time.time_duration_minutes_full_ago", [Math.floor(durationInMinutes)]).toLowerCase();
  } else if (durationInMinutes < 24 * 60) {
    return i18n.t("time.time_duration_hours_full_ago", [Math.floor(durationInMinutes / 60)]).toLowerCase();
  } else if (date.year() === currentDate.year()) {
    return date.format(DATE_FORMAT_DAYS_WITH_SHORTHAND_MONTH);
  } else {
    return date.format(DATE_FORMAT_DEFAULT);
  }
}

export function composeDateRange(startDateString, endDateString, eventTimezone) {
  const startMoment = dayjs(startDateString).tz(eventTimezone);
  const endMoment = dayjs(endDateString).tz(eventTimezone);

  if (hasNoDaysBetween(startMoment, endMoment)) {
    return startMoment.format(DATE_FORMAT_DEFAULT);
  } else if (!hasNoDaysBetween(startMoment, endMoment) && !hasDifferentMonth(startMoment, endMoment)) {
    return printRangedDates(
      startMoment.format(DATE_FORMAT_ONLY_DAYS),
      endMoment.format(DATE_FORMAT_DEFAULT)
    );
  } else if (hasDifferentMonth(startMoment, endMoment) && !hasDifferentYear(startMoment, endMoment)) {
    return printRangedDates(
      startMoment.format(DATE_FORMAT_DAYS_WITH_SHORTHAND_MONTH),
      endMoment.format(DATE_FORMAT_DEFAULT)
    );
  } else if (hasDifferentYear(startMoment, endMoment)) {
    return printRangedDates(
      startMoment.format(DATE_FORMAT_DEFAULT_NUMERIC),
      endMoment.format(DATE_FORMAT_DEFAULT_NUMERIC)
    );
  }
}

export function hasNoDaysBetween(startMoment, endMoment) {
  return startMoment.diff(endMoment, "days") === 0;
}

export function hasDifferentMonth(startMoment, endMoment) {
  return startMoment.month() !== endMoment.month();
}

export function hasDifferentYear(startMoment, endMoment) {
  return startMoment.year() !== endMoment.year();
}

export function printRangedDates(startString, endString) {
  return startString + " - " + endString;
}

export function generateDaysFromDateRange(startDateString, endDateString) {
  let startMoment = dayjs(startDateString);
  let endMoment = dayjs(endDateString);
  let days = [];
  while (startMoment < endMoment) {
    days = [...days, { id: startMoment.valueOf(), only_date: startMoment.toISOString() }];
    startMoment = startMoment.add(1, 'day');
  }
  return days;
}

export function getTimestampRangeFromEventDay(eventDay) {
  const dayMoment = dayjs(eventDay.only_date);
  const startTimestamp = dayMoment.valueOf();
  const endTimestamp = startTimestamp + 24 * 60 * 60 * 1000;
  return { startTimestamp, endTimestamp };
}

export function isTimestampBetweenRange(timestamp, rangeStart, rangeEnd) {
  return timestamp >= rangeStart && timestamp <= rangeEnd;
}

export function areTimestampRangesColliding(firstStart, firstEnd, secondStart, secondEnd) {
  return (
    isTimestampBetweenRange(firstStart, secondStart, secondEnd) ||
    isTimestampBetweenRange(firstEnd, secondStart, secondEnd) ||
    isTimestampBetweenRange(secondStart, firstStart, firstEnd) ||
    isTimestampBetweenRange(secondEnd, firstStart, firstEnd)
  );
}

export function parseTimestampToAvailabilityTime(timestamp, eventTimezone) {
  return dayjs(timestamp).tz(eventTimezone).format("HH   :   mm");
}

// Enable backward compatibility
export default {
  composeDateRange,
  hasNoDaysBetween,
  hasDifferentMonth,
  hasDifferentYear,
  printRangedDates,
  composeElapsedTime,
  generateDaysFromDateRange,
  areTimestampRangesColliding,
  isTimestampBetweenRange,
  getTimestampRangeFromEventDay,
  parseTimestampToAvailabilityTime,
  composeSessionRange,
};
