<template>
  <div class="radio-button-container" @click.stop="onClick" :style="containerStyle">
    <div class="radio-button" :class="classes">
      <div class="inner-circle" :class="classes" :style="innerCircleStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioButton",

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    containerStyle() {
      const size = this.size + "px";
      return {
        width: size,
        height: size,
      };
    },

    innerCircleStyle() {
      const size = this.size * 0.6 + "px";
      const margin = -this.size * 0.3 + "px";
      return {
        width: size,
        height: size,
        marginLeft: margin,
        marginTop: margin,
      };
    },

    classes() {
      return {
        selected: this.value,
        "is-primary": this.isPrimary
      };
    },
  },

  methods: {
    onClick() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped></style>
