<template>
  <div class="modal-card" v-if="user">
    <header class="modal-card-head">
      <profile-picture class="is-100x100" :picture="picture" :initials="initials"></profile-picture>
      <button class="delete" type="button" @click="$parent.close"></button>
      <div class="title">{{ fullName }}</div>
      <div class="subtitle">{{ subtitle }}</div>
      <div class="country-list block" v-if="countries && countries.length">
        <CountriesList :countries="countries"/>
      </div>

      <div v-if="!isCurrentUser" class="button-section">
        <div v-if="inboxEnabled" @click="openInbox()" class="circle-button">
          <message-icon class="has-fill-primary has-stroke-primary"></message-icon>
        </div>
<!--        <div @click="openJitsiCall()" class="circle-button video-call-button">-->
<!--          <video-call-icon class="has-fill-primary"></video-call-icon>-->
<!--        </div>-->
        <div @click="openBM" class="circle-button bm-button" v-if="commonBMComponents && commonBMComponents.length">
          <b-m-icon class="has-fill-primary has-stroke-primary"></b-m-icon>
        </div>
      </div>
      <div class="mt-1" v-if="!isCurrentUser">
        <friends-button :user-uuid="userId" :state="friendState"></friends-button>
      </div>
    </header>

    <section class="modal-card-body">
      <div v-if="biography">
        <div class="section-title">
          {{ $t("person_profile.user_biography") }}
        </div>
        <div class="section-text mt-6px">
          {{ biography }}
        </div>
      </div>

      <div v-if="showMyGoalsSection">
        <div class="section-title">
          {{ $t("person_profile.user_my_goals") }}
        </div>
        <div v-if="whatIOffer" class="section-subtitle mt-6px">
          {{ $t("person_profile.user_what_i_offer") }}
          <div class="section-text mt-2px">
            {{ whatIOffer }}
          </div>
        </div>
        <div v-if="whatINeed" class="section-subtitle mt-6px">
          {{ $t("person_profile.user_what_i_need") }}
          <div class="section-text mt-2px">
            {{ whatINeed }}
          </div>
        </div>
      </div>

      <div class="mt-2" v-if="showContactDetails && contacts.length > 0">
        <template v-for="(contact, index) in contacts">
          <contact-link v-bind="contact" :key="index"></contact-link>
        </template>
      </div>
      <div class="mt-2" v-if="socialLinks.length > 0">
        <social-links class="contact-links" size="40" :links="socialLinks"></social-links>
      </div>
    </section>

    <footer class="modal-card-foot" :style="{ padding: '5px' }"></footer>
  </div>
</template>

<script>
import {Constants} from "@/web/constants";
import {mapGetters, mapActions, mapState} from "vuex";
import {
  buildContactList,
  buildSocialLinks,
  fullName,
  initials,
  positionAndCompany,
} from "@/shared/utils";
import SocialLinks from "@/web/components/shared/SocialLinks";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import ContactLink from "@/web/components/shared/ContactLink";
// import VideoCallIcon from "@/assets/icons/videocam.svg";
import MessageIcon from "@/assets/icons/inbox_small.svg";
import BMIcon from "@/assets/icons/business_matching.svg";
import CountriesList from "@/web/components/profile/CountriesList";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import FriendsButton from "@/web/components/profile/FriendsButton";
import BMModal from "@/web/components/businessmatching/BMModal";

export default {
  name: "UserProfileModal",

  mixins: [AuthorizedRouteMixin],

  components: {
    FriendsButton,
    ProfilePicture,
    SocialLinks,
    ContactLink,
    CountriesList,
    MessageIcon,
    // VideoCallIcon
    BMIcon,
  },

  props: ["userId"],

  data() {
    return {
      commonBMComponents: null,
    };
  },

  mounted() {
    this.loadUserProfile(this.userId).catch(err => this.$parent.close());
    if (this.currentUserId !== this.userId) {
      this.getCommonComponentsList(this.userId).then(components => {
        this.commonBMComponents = components.filter(
          component =>
            component.component_name === Constants.BUSINESS_MATCHING_COMPONENT_TYPE &&
            component.additional_info.business_matching_type !== Constants.BUSINESS_MATCHING_TYPE_STRICT
        );
      });
    }
  },

  computed: {
    ...mapGetters(["inboxEnabled"]),
    ...mapGetters("currentUser", {currentUserId: "uuid"}),
    ...mapGetters("attendees", ["getUserById"]),
    ...mapGetters("friends", ["hasPendingInvitation", "hasRequestedInvitation"]),

    user() {
      return this.getUserById(this.userId);
    },

    isCurrentUser() {
      return this.user.user_identity_token === this.currentUserId;
    },

    fullName() {
      return fullName(this.user);
    },

    picture() {
      return this.user.picture;
    },

    biography() {
      return this.user.biography;
    },

    initials() {
      return initials(this.user);
    },

    subtitle() {
      return positionAndCompany(this.user);
    },

    countries() {
      return this.user.country;
    },

    socialLinks() {
      return buildSocialLinks(this.user);
    },

    contacts() {
      return buildContactList(this.user);
    },

    showContactDetails() {
      return this.user.show_contact_details;
    },

    whatIOffer() {
      return this.user.what_i_offer;
    },

    whatINeed() {
      return this.user.what_i_need;
    },

    showMyGoalsSection() {
      return this.whatIOffer || this.whatINeed;
    },

    friendState() {
      if (this.user.is_friend) {
        return Constants.FRIEND_ACCEPTED;
      } else if (this.hasPendingInvitation(this.userId)) {
        return Constants.FRIEND_PENDING;
      } else if (this.hasRequestedInvitation(this.userId)) {
        return Constants.FRIEND_REQUESTED;
      } else {
        return Constants.FRIEND_NOT_FRIEND;
      }
    },
  },

  methods: {
    ...mapActions("attendees", ["loadUserProfile"]),
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),
    ...mapActions("components", ["getCommonComponentsList"]),

    openInbox() {
      this.checkIfThreadExists({userId: this.user.user_identity_token}).then(result => {
        if (result.data) {
          const threadId = result.data.id;
          this.openInboxBubbleWithThread(threadId);
          this.$parent.close();
        } else {
          this.openInboxBubbleWithUser(this.user.user_identity_token);
          this.$parent.close();
        }
      });
    },

    openBM() {
      if (this.commonBMComponents && this.commonBMComponents.length) {
        this.$buefy.modal.open({
          parent: this,
          component: BMModal,
          hasModalCard: true,
          canCancel: true,
          trapFocus: true,
          props: {
            components: this.commonBMComponents,
            userUuid: this.userId,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-card-body {
  padding-bottom: 30px !important;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  margin-top: 12px;
}

.subtitle {
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  text-align: center;
  font-feature-settings: "liga" off;
  color: #828282;
  margin-top: 8px;
}

.section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 31px;
  line-height: 27px;
  font-feature-settings: "liga" off;
  color: var(--text-color-primary);
}

.section-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  color: var(--primary-color);
}

.section-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: var(--text-color-secondary);
  white-space: pre-line;
}

.mt-6px {
  margin-top: 6px;
}

.mt-2px {
  margin-top: 2px;
}

.contact-links {
  align-items: center;
  justify-content: center;
}

.button-section {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.video-call-button {
  padding: 5px;
}

.bm-button {
  padding: 5px;
}
</style>
