<template>
  <div class="has-fullwidth">
    <a
      class="button is-primary-inverted px-2 has-fullwidth"
      :href="videoCallUrl"
      v-if="videoCallUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      <webinar-icon class="mr-1 live-stream-icon"></webinar-icon>
      <span class="mr-1 is-primary">{{ $t("common.watch_webinar") }}</span>
      <arrow-icon class="arrow-icon-color"></arrow-icon>
    </a>

    <div v-if="description" class="has-fullwidth mt-3">
      <div class="section-text" v-html="description"></div>
    </div>

    <!-- Video presentation -->
    <embed-video class="exhibitor-details-embed-video mt-3" :source="presentationVideoUrl" v-if="presentationVideoUrl"></embed-video>



    <div class="columns has-fullwidth is-marginless is-multiline pt-0">
      <div class="column is-half p-0" v-if="contactsVisible">
        <div class="has-fullwidth">
          <div class="section-title mb-1">
            {{ $t("common.contact_details") }}
          </div>
          <template v-for="(contact, index) in contacts">
            <contact-link v-bind="contact" :key="index"></contact-link>
          </template>
        </div>
      </div>
      <div class="column is-half p-0" v-if="externalUrl">
        <div class="has-fullwidth">
          <div class="section-title mb-1">
            {{ $t("common.contact_website") }}
          </div>
          <a :href="externalUrl" target="_blank" class="section-text has-text-link" rel="noopener noreferrer">
            {{ externalUrl }}
          </a>
        </div>
      </div>
      <div class="column is-half p-0" v-if="showSocialMedia && socialLinks.length > 0">
        <div class="has-fullwidth" >
          <div class="section-title mb-1">
            {{ $t("common.social_media") }}
          </div>
          <div class="has-fullwidth is-flex is-justify-content-flex-start">
            <social-link-list :socials="socialLinks"></social-link-list>
          </div>
        </div>
      </div>
    </div>

    <section class="has-fullwidth" v-if="contactPersons.length > 0 && lpMode">
      <h2 class="section-title mb-1">{{ $t("common.contact_person") }}</h2>
      <paginated-content :items="contactPersons" :perPage="2">
        <template v-slot="{ displayItems }">
          <div v-for="(contact, index) in displayItems" :key="index">
            <contact-card v-bind="contact" :inbox-enabled="inboxEnabled" @inbox-opened="$emit('close-modal')"></contact-card>
          </div>
        </template>
      </paginated-content>
    </section>

    <!-- Attachments -->
    <section class="has-fullwidth mt-2" v-if="attachments.length > 0">
      <h2 class="section-title mb-1">{{ $t("common.attachments_title") }}</h2>
      <attachment-list :attachments="attachments" column-class="is-half"></attachment-list>
    </section>
  </div>
</template>

<script>
import EmbedVideo from "@/web/components/shared/EmbedVideo";
import ContactLink from "@/web/components/shared/ContactLink";
import ContactCard from "@/web/components/shared/ContactCard";
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import AttachmentList from "@/web/components/shared/AttachmentList";
import WebinarIcon from "@/assets/icon_live_stream.svg";
import ArrowIcon from "@/assets/banner/arrow-icon.svg";
import SocialLinkList from "@/web/components/shared/SocialLinkList";
import {buildContactList, buildContactModels, buildSocialLinks} from "@/shared/utils";

export default {
  name: "ExhibitorBody",

  components: {
    EmbedVideo,
    ContactLink,
    ContactCard,
    PaginatedContent,
    AttachmentList,
    WebinarIcon,
    ArrowIcon,
    SocialLinkList,
  },

  props: {
    exhibitor: {
      type: Object,
      required: true,
    },
    isAuthorized: {
      type: Boolean,
      required: true,
    },
    lpMode: {
      type: Boolean,
      required: true,
    },
    inboxEnabled: {
      type: Boolean,
      required: true,
    }
  },

  computed: {
    description() {
      return this.exhibitor.description && this.$options.filters.linkify(this.exhibitor.description);
    },

    socialLinks() {
      return buildSocialLinks(this.exhibitor);
    },

    contacts() {
      return buildContactList(this.exhibitor);
    },

    showContactList() {
      return !this.exhibitor.for_logged_contact || this.isAuthorized;
    },

    showSocialMedia() {
      return !this.exhibitor.for_logged_sm || this.isAuthorized;
    },

    contactPersons() {
      return buildContactModels([...this.exhibitor.exhibitor_contact_people, ...this.exhibitor.exhibitor_contact_users]);
    },

    externalUrl() {
      const url = this.exhibitor.www;
      const hasHttp = url && url.startsWith("http");
      return hasHttp && this.exhibitor.www;
    },

    presentationVideoUrl() {
      return this.exhibitor.promotion_film_url;
    },

    attachments() {
      const attachments = this.exhibitor.exhibitor_attachments;
      return attachments.map(attachment => ({
        type: "file",
        name: attachment.file_name,
        url: attachment.file_url,
      }));
    },

    videoCallUrl() {
      return this.exhibitor.video_call_url;
    },

    contactsVisible() {
      return this.showContactList && this.contacts.length > 0;
    },
  }
};
</script>

<style scoped>

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  margin-top: 12px;
}

.subtitle {
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  text-align: center;
  font-feature-settings: "liga" off;
  color: #828282;
  margin-top: 8px;
}

.section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 31px;
  line-height: 27px;
  font-feature-settings: "liga" off;
  color: var(--text-color-primary);
}

.section-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: var(--text-color-secondary);
  margin-top: 6px;
  white-space: pre-line;
}

.contact-links {
  align-items: center;
  justify-content: center;
}

.image img {
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
}

.live-stream-icon {
  width: 27px;
  height: 27px;
  fill: var(--primary-color);
}

.arrow-icon-color {
  stroke: var(--primary-color);
}
</style>
