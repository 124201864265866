<template>
  <button class="button is-fullwidth has-text-weight-bold is-active">
    <span class="notranslate" style="margin-right: 4px">{{ day.only_date | date("ddd") }} &bullet;</span>
    <span class="notranslate">{{ day.only_date | date("D MMM") }}</span>
  </button>
</template>

<script>
import dayjs from '@/shared/utils/day';

export default {
  name: "EventDay",

  props: {
    day: {
      type: Object,
      required: true,
    }
  },

  filters: {
    date(value, format) {
      return dayjs(value).format(format);
    },
  },

  computed: {

  }
};
</script>
