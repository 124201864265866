<template>
  <div class="columns is-flex-wrap">
    <div v-for="(attachment, index) in attachments" :key="index" class="column is-half">
      <file-attachment v-if="attachment.type === 'file'" :file="attachment"></file-attachment>
      <link-attachment v-if="attachment.type === 'link'" :link="attachment"></link-attachment>
    </div>
  </div>
</template>

<script>
import FileAttachment from "@/web/components/shared/FileAttachment";
import LinkAttachment from "@/web/components/shared/LinkAttachment";

export default {
  name: "AttachmentList",

  components: {
    FileAttachment,
    LinkAttachment
  },

  props: ["attachments"]
};
</script>
