<template>
  <attachment>
    <link-icon class="link-icon" slot="right"></link-icon>
    <a :href="targetUrl" target="_blank" rel="noopener noreferrer">{{ title }}</a>
  </attachment>
</template>

<script>
import LinkIcon from "@/assets/icons/link.svg";
import Attachment from "@/web/components/shared/Attachment";

export default {
  name: "LinkAttachment",

  components: {
    LinkIcon,
    Attachment
  },

  props: ["link"],

  computed: {
    title() {
      return this.link && this.link.name;
    },

    targetUrl() {
      return this.link && this.link.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.link-icon {
  fill: #4f4f4f;
  fill: var(--primary-color);
  display: block;
  width: 24px;
  height: 24px;
}
</style>
