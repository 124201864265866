import VueCustomElement from "vue-custom-element";
import Buefy from "buefy";
import Multiselect from "vue-multiselect";
import VueI18n from "vue-i18n";
import Theme from "@/theme";
import ClickOutside from "@/shared/directives/click-outside";
import dayjs from "@/shared/utils/day";
import "@/shared/utils/dayjs-locales";
import { pluckError } from "@/widgets/shared/utils";
import linkifyHtml from "linkifyjs/html";
import { createLocalization } from "@/widgets/shared/locales";

export function initializeVueWidget(Vue) {
  Vue.use(VueCustomElement);
  Vue.use(Buefy);
  Vue.use(Multiselect);
  Vue.use(VueI18n);
  Vue.use(Theme, false);
  Vue.use(ClickOutside);
  Vue.config.productionTip = false;

  // Change locale
  Vue.prototype.$setLocale = function (locale) {
    dayjs.locale(locale);
    this.$i18n.locale = locale;
  };

  // Globally accessible filters
  Vue.filter("pluckError", value => pluckError(value));

  Vue.filter("linkify", value =>
    linkifyHtml(value, {
      defaultProtocol: "https",
      target: {
        url: "_blank",
      },
    })
  );
}

export function beforeCreateVueInstance({ store }) {
  return function (rootDef) {
    const rootNode = rootDef.el.getRootNode();
    // const els = document.querySelectorAll('meeting-app-exhibitors');
    if (rootNode instanceof ShadowRoot) {
      applyStylesForShadowRoot(rootNode);
      rootDef.shadowRoot = rootNode;
    } else {
      rootDef.shadowRoot = document.head;
    }

    rootDef.store = store;
    rootDef.i18n = createLocalization();
    return rootDef;
  };
}

function applyStylesForShadowRoot(rootNode) {
  const styles =
    process.env.NODE_ENV === "development"
      ? document.head.querySelectorAll("style")
      : document.head.querySelectorAll('link[id="ma-widget-style"]');
  styles.forEach(style => {
    let clonedNode = style.cloneNode(true);
    clonedNode.removeAttribute("disabled");
    rootNode.append(clonedNode);
  });

  const mdiIconStyles = 'https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css';
  const linkElement = document.createElement("link");
  linkElement.rel = "stylesheet";
  linkElement.href = mdiIconStyles;
  document.head.append(linkElement);
}
